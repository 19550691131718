
import {computed, defineComponent, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import {useI18n} from "vue-i18n";
export default defineComponent({
  name: "password-reset",
  props: {
    token: {
      type: String,
      required: true
    }
  },
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      password: Yup.string().required(t(`${currentLanguage.value}.messages.email_is_a_required_field`)),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], t(`${currentLanguage.value}.messages.the_passwords_must_match`)),
    });
    
    


    const parseParams = (querystring) => {
      // parse query string
      const params = new URLSearchParams(querystring);
      const obj = {};
      params.forEach((v, k) => {
        obj[k] = v;
      });

     
      return obj;
    };

   

    //Form submit function
    const onSubmitForgotPassword = (values) => {
     const data = parseParams(window.location.search);
      const token = data['token'];
    
      const email = localStorage.getItem('email')
      if(!values.password || !values.password_confirmation){
        return;
      }
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
        values.email = email;
        values.token = token;
        // Send login request
        ApiService.post('password/reset', values).then( ( data ) => {

          Swal.fire({
            title: t(`${currentLanguage.value}.common.success`),
            text: t(`${currentLanguage.value}.messages.your_password_has_been_successfully_reset`),
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then(() => {
            router.push('/sign-in');
          });

        }).catch((err) => {

          Swal.fire({
            title: 'Erro!',
            text: t(`${currentLanguage.value}.messages.we_had_a_problem_requesting_a_password_reset_check_that_the_email_is_correct`),
            icon: 'error',
            confirmButtonText: 'Ok'
          })

        });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
    
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      currentLanguage
    };
  },
});
